import React from "react";
import { useTranslation } from "react-i18next";
import { Controller, SubmitHandler, useFormContext } from "react-hook-form";
import { MuiOtpInput } from "mui-one-time-password-input";
import Box from "@mui/material/Box";
import { getErrorMessage } from "../../../utils/formsUtils";
import styles from "./MobilePin.module.scss";
import "./Otp.scss";
import { Button } from "../../../components/common/Button";
import { ErrorMessage } from "../../../components/ErrorMessage";

interface MobileVerificationProps {
  onResendCode: () => void;
  onSubmit: (pin: string) => void;
  isSubmitDisabled: boolean;
}

type PinValues = {
  pin: string;
};

export const MobilePin = ({
  onResendCode,
  onSubmit,
  isSubmitDisabled,
}: MobileVerificationProps) => {
  const { t } = useTranslation();

  const { control, handleSubmit, getValues } = useFormContext<PinValues>();

  const onFormSubmit: SubmitHandler<PinValues> = (data: PinValues) =>
    onSubmit(data.pin);

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === "Enter") {
      onSubmit(getValues("pin"));
    }
  };

  /**
   * A function that validates each character during keyPress / paste events.
   * If a user fills in an invalid character (like a letter instead of a number), it will not be displayed.
   * That's recommended solution by MuiOtpInput Documentation.
   * {@link https://viclafouch.github.io/mui-otp-input/docs/api-reference/#validatechar MuiOtpInput API Reference}
   */
  const matchIsNumeric = (char: string) =>
    !isNaN(Number(char)) && char.toString().trim() !== "";

  /**
   * <input type="number"> elements do not support use of the pattern attribute for making entered values conform
   * to a specific regex pattern. The rationale for this is that number inputs can't contain anything except numbers,
   * and you can constrain the minimum and maximum number of valid digits using the min and max attributes
   * {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/number MDN HTML input Reference}
   */
  const preventNonNumericalInput = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (!matchIsNumeric(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <>
      <section className={styles.smsBox} data-testid="pin-code">
        <p>{t("PHONE_CODE")}</p>
        <button
          onClick={onResendCode}
          className={styles.linkImitation}
          type="button"
          data-testid="resend-code"
        >
          {t("MISSING_CODE")}
        </button>
        <form
          className={styles.smsInputsForm}
          onSubmit={handleSubmit(onFormSubmit)}
          id="PIN"
        >
          <section className={styles.smsInputsWrapper}>
            <Controller
              name="pin"
              control={control}
              render={({ field, fieldState }) => (
                <Box>
                  {
                    <MuiOtpInput
                      {...field}
                      data-testid="pin"
                      className="otp"
                      onComplete={onSubmit}
                      validateChar={matchIsNumeric}
                      autoFocus
                      TextFieldsProps={{
                        type: "number",
                        inputProps: {
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                        },
                        onKeyPress: preventNonNumericalInput,
                      }}
                    />
                  }
                  {fieldState.invalid ? (
                    <ErrorMessage>
                      {t(getErrorMessage(fieldState.error))}
                    </ErrorMessage>
                  ) : null}
                </Box>
              )}
            />
          </section>
        </form>
      </section>
      <Button
        id="submit-pin-button"
        data-testid="submit-pin-button"
        disabled={isSubmitDisabled}
        type="submit"
        form="PIN"
        onKeyDown={handleKeyDown}
      >
        {t("CONTINUE")}
      </Button>
    </>
  );
};
