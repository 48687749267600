import yup from "../../yup/yupInterface";
import {
  DEFAULT_MINIMUM_PHONE_NUMBER_LENGTH,
  MOBILE_RULES,
} from "../../../config/constants";

export type MobileFormValues = {
  countryCode: string;
  phoneNumber: string;
};

export const MobileSchema = () => {
  return yup.object({
    countryCode: yup.string().required("COUNTRY_CODE_REQUIRED"),
    phoneNumber: yup
      .string()
      .required("REQUIRED")
      .test(
        "phone-number-length",
        "INCOMPLETE_MOBILE",
        function validate(value) {
          const { countryCode } = this.parent as MobileFormValues;
          const phoneNumberMinLength: number =
            MOBILE_RULES[countryCode?.replace(/[^0-9]/g, "")]
              ?.phoneNumberMinLength || DEFAULT_MINIMUM_PHONE_NUMBER_LENGTH;

          return value.length >= Number(phoneNumberMinLength);
        }
      ),
  });
};
