import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

import { getCountryConfig } from "../config/countries/countryUtil";

export type FormFieldError =
  | FieldError
  | Merge<FieldError, FieldErrorsImpl>
  | undefined;

export const addDaysToDate = (date: Date, days: number) => {
  return new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
};

export const getFormattedDate = (date: Date) => {
  return `${String(date.getDate()).padStart(2, "0")}/${String(
    date.getMonth() + 1
  ).padStart(2, "0")}/${date.getFullYear()}`;
};

export const getErrorMessage = (error: FormFieldError) => {
  const errorMessage =
    typeof error?.message === "string" ? error?.message?.split("|")[0] : "";
  return errorMessage && errorMessage !== "undefined" ? errorMessage : "";
};

export const getMaxLengthFor = (country: string, fieldName: string) => {
  return getCountryConfig(country)?.[fieldName] as number;
};
