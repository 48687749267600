import { HomeAddressState } from "./homeAddressStore";
import { WithTypeContaining } from "../../../types/utilityTypes";

export const createHomeAddressFromForm = ({
  province,
  addressLine1,
  addressLine2,
  city,
  postalCode,
  district,
  subdistrict,
  state,
}: WithTypeContaining<HomeAddressState>): HomeAddressState => ({
  province,
  addressLine1,
  addressLine2,
  city,
  postalCode: postalCode.toUpperCase(),
  district,
  subdistrict,
  state
});
