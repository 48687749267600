import * as React from "react";
import styles from "./styles.module.scss";

export interface ErrorMessageInterface
  extends React.HTMLProps<HTMLSpanElement> {
  children?: React.ReactNode;
  className?: string;
}

export const ErrorMessage = ({
  children,
  className,
  ...props
}: ErrorMessageInterface) =>
  children ? (
    <span
      role="alert"
      className={`${styles.errorMessage} ${className || ""}`}
      {...props}
    >
      {children}
    </span>
  ) : null;
