import React, { LegacyRef } from "react";
import { useMeasure } from "react-use";
import { useTranslation } from "react-i18next";

import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage } from "../ErrorMessage";
import { determinePhoneNumberLength } from "../../utils/determinePhoneNumberLength";
import { CountryCode } from "../common/CountryCode/CountryCode";
import { PhoneNumber } from "./PhoneNumber";
import { getErrorMessage } from "../../utils/formsUtils";
import { useProfileStore } from "../../features/initialRegistration/profileStore";
import styles from "./styles.module.scss";
import globalStyles from "../../globalStyles.module.scss";
import { useShallow } from "zustand/react/shallow";
import { getCountryConfig } from "../../config/countries/countryUtil";
import { MobileFormValues } from "../../features/initialRegistration/schemas/MobileSchema";

type MobileInputProps = {
  countryCodeDisabled: boolean;
  phoneNumberDisabled: boolean;
  onKeyPressed: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onPhoneChange: () => void;
};

export const MobileInput = ({
  countryCodeDisabled,
  phoneNumberDisabled,
  onKeyPressed,
  onPhoneChange,
}: MobileInputProps) => {
  const { t } = useTranslation();
  const resetMobileVerifications = useProfileStore(
    useShallow((state) => state.resetMobileVerification)
  );
  const country = useProfileStore(useShallow((state) => state.country));
  const countryConfig = getCountryConfig(country);
  const showDisclaimer = countryConfig?.showMobilePhoneDisclaimer;

  const [phoneNumberContainerRef, { width: phoneContainerWidth }] =
    useMeasure();

  const {
    formState: { errors },
    getValues,
    setValue,
    control,
    trigger,
  } = useFormContext<MobileFormValues>();

  const maxPhoneLength = determinePhoneNumberLength(getValues("countryCode"));

  const resetPhoneValues = () => {
    resetMobileVerifications();
    onPhoneChange();
    setValue("phoneNumber", "", {
      shouldValidate: true,
    });
  };

  return (
    <>
      <div
        className={styles.phoneNumber}
        ref={phoneNumberContainerRef as LegacyRef<HTMLDivElement>}
      >
        <div>
          <Controller
            control={control}
            name="countryCode"
            defaultValue={countryConfig?.defaultCountryCode as string}
            render={({ field: { onBlur, onChange, value } }) => (
              <CountryCode
                dropdownWidth={phoneContainerWidth}
                error={Boolean(errors.countryCode)}
                onBlur={onBlur}
                onChange={(countryCodeWithName: string) => {
                  resetPhoneValues();
                  onChange(countryCodeWithName);
                }}
                value={value}
                label={t("COUNTRY_CODE")}
                disabled={countryCodeDisabled}
              />
            )}
          />
          <div>
            {errors.countryCode && (
              <div className={styles.countryCodeBig}>
                <ErrorMessage data-testid="country-code-error">
                  {t(getErrorMessage(errors.countryCode))}
                </ErrorMessage>
              </div>
            )}
          </div>
        </div>
        <div>
          <Controller
            control={control}
            name="phoneNumber"
            defaultValue=""
            render={({ field: { onBlur, onChange, value } }) => (
              <PhoneNumber
                error={errors.phoneNumber}
                id="mobile"
                label={t("MOBILE")}
                maxLength={maxPhoneLength}
                onBlur={onBlur}
                onKeyPressed={onKeyPressed}
                phoneNumberDisabled={phoneNumberDisabled}
                onPhoneNumberChange={async (phoneNumber) => {
                  resetMobileVerifications();
                  onPhoneChange();
                  onChange(phoneNumber);
                  await trigger(["countryCode", "phoneNumber"]);
                }}
                value={value}
              />
            )}
          />
          {(errors.countryCode || errors.phoneNumber) && (
            <div className={styles.phoneNumberErrors}>
              <div>
                {errors.phoneNumber && (
                  <ErrorMessage data-testid="mobile-error">
                    {t(getErrorMessage(errors.phoneNumber))}
                  </ErrorMessage>
                )}
              </div>
            </div>
          )}
          {showDisclaimer && (
            <p
              className={`${globalStyles.disclaimer} ${styles.mobileDisclaimer}`}
            >
              {t("MOBILE_PHONE_DISCLAIMER")}
            </p>
          )}
        </div>
      </div>
      <p
        className={`${globalStyles.disclaimer} ${styles.mobileDisclaimer} ${styles.oneTimeVer}`}
      >
        {t("VERIFICATION_CODE_DISCLAIMER")}
      </p>
    </>
  );
};
