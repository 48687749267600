import { getForbiddenWordsForCountry } from "../../utils/forbiddenWordsForCountry";
import { AttachmentType } from "../../features/personal-info/attachments/attachmentsStore";
import { CountryFormConfiguration, DEFAULT_NAMES_PATTERN } from "../constants";
import {
  DEFAULT_ADDRESS_LINE_PATTERN,
  defaultCountryConfigFieldLengths,
  defaultShippingAddressCareOfNameValidation,
} from "./defaults";
import { staticAssetsApi } from "../../api/staticAssets";
import {queryClient} from "../queryClient";

type TaiwanAddress = {
  state: string;
  city: string;
  postalCode: string;
}

const getAddressData = async () =>
  await queryClient.fetchQuery({
    queryKey: ['zh-TW', 'addresses'],
    queryFn: async (): Promise<TaiwanAddress[]> => await staticAssetsApi.getStaticAssets<TaiwanAddress[]>("zh-tw", "addresses.json"),
    staleTime: Infinity,
    gcTime: Infinity,
  })

const getAddresses = async () => queryClient.getQueryData<TaiwanAddress[]>(['zh-TW', 'addresses']) ?? await getAddressData()

const getStates = async (): Promise<string[]> =>
  [...new Set((await getAddresses()).map(address => address.state))];


const getCities = async (selectedState: string): Promise<string[]> => {
  const cities = (await getAddresses())
    .filter(address => address.state === selectedState)
    .map(address => address.city);

  return [...new Set(cities)];
}

const getPostalCodes = async (selectedCity: string): Promise<string[]> => {
  const postalCodes = (await getAddresses())
    .filter(address => address.city === selectedCity)
    .map(address => address.postalCode);

  return [...new Set(postalCodes)];
}

export const Taiwan: CountryFormConfiguration = {
  id: "zh-TW",
  forms: {
    personalInfo: {
      firstName: {
        name: "firstName",
        label: "FIRST_NAME",
        type: "text",
        sanitize: true,
        bottomDescription: "FIRST_NAME_DISCLAIMER",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "FIRST_NAME_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.firstName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "FIRST_NAME_SIZE",
          },
          trim: true,
        },
      },
      lastName: {
        name: "lastName",
        label: "LAST_NAME",
        type: "text",
        sanitize: true,
        bottomDescription: "LAST_NAME_DISCLAIMER",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "LAST_NAME_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.lastName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "LAST_NAME_SIZE",
          },
          trim: true,
        },
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        bottomDescription: "DELIVERY_DISCLAIMER",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 40, errorMessage: "TOO_LONG" },
          pattern: {
            value: DEFAULT_ADDRESS_LINE_PATTERN,
            errorMessage: "FORBIDDEN_WORD",
          },
          trim: true,
        },
      },
      state: {
        name: "state",
        label: "STATE",
        type: "asyncAutocomplete",
        defaultValue: "",
        getOptions: getStates,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "asyncAutocomplete",
        defaultValue: "",
        dependenciesNames: ["state"],
        getOptions: getCities,
        rule: {
          visibleOn: ["state", Boolean],
        },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "asyncAutocomplete",
        defaultValue: "",
        dependenciesNames: ["city"],
        getOptions: getPostalCodes,
        rule: {
          visibleOn: ["city", Boolean],
        },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
      },
      dateOfBirth: {
        name: "dateOfBirth",
        label: "BIRTHDAY",
        type: "date",
        bottomDescription: "BIRTHDAY_DISCLAIMER",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          adultAge: { value: 18, errorMessage: "TOO_YOUNG" },
        },
      },
      tins: {
        name: "tins",
        label: "tins",
        type: "tins",
        validation: {
          required: { value: false },
        },
      },
    },
    shippingAddress: {
      careOfName: {
        name: "careOfName",
        label: "HMP_CARE_OF_NAME",
        sanitize: true,
        type: "text",
        validation: defaultShippingAddressCareOfNameValidation,
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        bottomDescription: "DELIVERY_DISCLAIMER",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: {
            value: getForbiddenWordsForCountry("zh-TW"),
            errorMessage: "PO_BOX",
          },
          pattern: {
            value: DEFAULT_ADDRESS_LINE_PATTERN,
            errorMessage: "FORBIDDEN_WORD",
          },
          trim: true,
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 40, errorMessage: "TOO_LONG" },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 3, errorMessage: "TOO_LONG" },
          minLength: { value: 3, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: {
            value: /^[0-9]{3}$/,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          mask: "###",
        },
      },
    },
  },
  addressLength: 40,
  addressPattern: DEFAULT_ADDRESS_LINE_PATTERN,
  cityLength: 40,
  postalCodeLength: 3,
  postalCodeMask: "###",
  postalCodePattern: /^[0-9]{3}$/,
  postalCodeRequired: true,
  showDeliveryDisclaimer: true,
  defaultCountryValue: "Taiwan",
  firstNameLength: defaultCountryConfigFieldLengths.firstName,
  footerLogos: [
    {
      src: "logos/dsa.png",
      alt: "dsa logo",
    },
  ],
  genderFieldRequired: false,
  lastNameLength: defaultCountryConfigFieldLengths.lastName,
  lastNameFirst: false,
  middleInitial: false,
  middleInitialLength: defaultCountryConfigFieldLengths.middleInitial,
  middleInitialRequired: false,
  mothersMaidenName: false,
  mothersMaidenNameLength: defaultCountryConfigFieldLengths.mothersMaidenName,
  mothersMaidenNameRequired: false,
  placeOfBirthRequired: false,
  hideFooterTerms: true,
  showDataPrivacyAcknowledgment: true,
  showDateOfBirthDisclaimer: true,
  showDocumentationBox: false,
  showFirstNameDisclaimer: true,
  showHmpBenefits: true,
  showHmpPickUpSection: false,
  showHmpPriceContent: true,
  showGsgBenefits: true,
  showLastNameDisclaimer: true,
  showLatinInfo: false,
  showLinkToCookiePolicy: true,
  showLinkToOrderConfirmation: true,
  showPdfContainer: true,
  showResidentRadioBox: false,
  showSocialMediaStatement: true,
  splitDeliveryTotal: false,
  spouseFirstName: false,
  spouseFirstNameLength: defaultCountryConfigFieldLengths.spouseFirstName,
  spouseMiddleName: false,
  spouseMiddleNameLength: defaultCountryConfigFieldLengths.spouseMiddleName,
  spouseLastName: false,
  spouseLastNameLength: defaultCountryConfigFieldLengths.spouseLastName,
  showOrderHandlerInformation: false,
  showPaymentAcknowledgment: true,
  showPaymentDisclaimer: true,
  supportedCountryCodes: ["zh"],
  defaultCountryCode: "+886|Taiwan",
  residentTins: [
    {
      key: "ssn",
      required: true,
      minLength: 10,
      maxLength: 10,
      pattern: /^[a-zA-Z]{1}[1-2]{1}[0-9]{8}$/,
      showTinDates: false,
      attachments: [
        {
          required: true,
          key: "resident-attachment-0",
          index: 0,
          type: AttachmentType.TAX_NUMBER,
        },
      ],
    },
  ],
} as const;
