import { isAdobeTagEnabled } from "../../config/adobeTag";
import * as Sentry from "@sentry/browser";

export const displayCookieBanner = (locale: string) => {
  if (!locale || !isAdobeTagEnabled()) {
    return;
  }
  try {
    if (localStorage) {
      localStorage.setItem("oa-locale", locale);
    }
    const lowerCaseLocale = locale.toLowerCase();
    console.log("Displaying cookie banner for locale: ", lowerCaseLocale);
    const cookieEvent = new CustomEvent("ShowCookieBanner", {
      detail: { locale: lowerCaseLocale },
    });
    console.log("Dispatching cookie event: ", cookieEvent);
    document.dispatchEvent(cookieEvent);
  } catch (error) {
    Sentry.captureException(error);
  }
};
