import DoneIcon from "@mui/icons-material/Done";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { useProfileStore } from "../../features/initialRegistration/profileStore";
import { useShallow } from "zustand/react/shallow";
import { getCountryConfig } from "../../config/countries/countryUtil";

export const GoldStandardBenefits = () => {
  const { t } = useTranslation();
  const country = useProfileStore(useShallow((state) => state.country));

  if (!getCountryConfig(country)?.showGsgBenefits) {
    return null;
  }

  return (
    <section className={styles.benefitSection} data-testid="benefit-section">
      <div className={styles.benefitBox}>
        <DoneIcon className={styles.benefitIcon} sx={{ fontSize: 60 }} />
        <span
          dangerouslySetInnerHTML={{
            __html: t("GSG_BENEFIT_1"),
          }}
        />
      </div>
      <div className={styles.benefitBox}>
        <DoneIcon className={styles.benefitIcon} sx={{ fontSize: 60 }} />
        <span
          dangerouslySetInnerHTML={{
            __html: t("GSG_BENEFIT_2"),
          }}
        />
      </div>
      <div className={styles.benefitBox}>
        <DoneIcon className={styles.benefitIcon} sx={{ fontSize: 60 }} />
        <span
          dangerouslySetInnerHTML={{
            __html: t("GSG_BENEFIT_3"),
          }}
        />
      </div>
      <div className={styles.benefitBox}>
        <DoneIcon className={styles.benefitIcon} sx={{ fontSize: 60 }} />
        <span
          dangerouslySetInnerHTML={{
            __html: t("GSG_BENEFIT_4"),
          }}
        />
      </div>
    </section>
  );
};
