import { Attachment } from "./attachmentsStore";
import { WithTypeContaining } from "../../../types/utilityTypes";

export type AttachmentsValues =
  | Record<`registrationNumber${string}attachment${string}`, Attachment>
  | undefined;

export const createAttachmentsFromForm = (
  personalInfoValues: WithTypeContaining<AttachmentsValues>
) => {
  return {
    attachments: Object.entries<Attachment>(personalInfoValues)
      .filter(([key]) => key.includes("attachment"))
      .map(([name, attachment]) => {
        return {
          fileMetadata: {
            name: attachment?.file?.name,
            type: attachment?.file?.type,
          },
          awsKey: attachment?.awsKey,
          wasUploaded: attachment?.wasUploaded,
          name: name,
          type: attachment?.type,
        };
      }),
  };
};

const getAttachmentsForRequest = (
  personalInfoValues: WithTypeContaining<AttachmentsValues>
) =>
  Object.entries<Attachment>(personalInfoValues)
    .filter(([key]) => key.includes("attachment"))
    .filter(([, attachment]) => (attachment)?.awsKey)
    .map(([, attachment]) => {
      return { fileName: attachment.awsKey, type: attachment.type };
    });

export const createAttachmentKeysRequestFromForm = (
  personalInfoValues: WithTypeContaining<AttachmentsValues>
) => {
  return {
    attachments: getAttachmentsForRequest(personalInfoValues),
  };
};
