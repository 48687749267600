export const CHECK_SPONSOR_PATH = "/v1/sponsorship-confirmations";
export const CONFIG_PATH = "/v1/config";
export const FILE_UPLOAD_PRESIGNED_PATH = "/v1/upload/requests";
export const ONLINE_CODE_VERIFICATION_PATH = "/v1/onlinecodes/verification";
export const PHONE_NUMBER_VERIFICATION_PATH = "/sms-verification";
export const SMS_PIN_VERIFICATION_PATH = "/v1/phone-numbers";
export const HMP_OWNED_READY_PATH = "/sso/hmp-owned-ready";
export const SAVE_PERSONAL_INFO_PATH = "/personal-info";
export const ORDER_PATH = "/order";
export const PURCHASABLE_MEMBER_PACKS_PATH =
  "/v2/purchasable-memberpacks-queries";
export const PRICED_MEMBER_PACKS_PATH = "/v3/price-memberpack";
export const PAYMENT_PATH = "/payment";
export const CONTRACT_PATH = "v1/contracts";
export const INITIAL_REGISTRATION_PATH = "v1/registrations/initial-data";
export const IDENTIFICATION_CARD_PATH = "/v1/identification-cards";
export const EXPERIAN_SEARCH_PATH = "/capture/address/v2/search";
