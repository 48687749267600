import React from "react";
import { useTranslation } from "react-i18next";
import { Logo } from "../../components/common/Logo";
import { Footer } from "../../components/common/Footer";
import { ShowValidSection } from "../../components/common/ShowValidSection";
import { GoldStandard } from "../../components/GoldStandard/GoldStandard";
import { useRegistrationStore } from "../../features/registration/registrationStore";
import { BreadcrumbStepper } from "../../features/breadcrumb/BreadcrumbStepper";
import { useShallow } from "zustand/react/shallow";

const GoldStandardPage = () => {
  const { t } = useTranslation();
  const readOnlyRegistration = useRegistrationStore(
    useShallow((state) => state.readOnlyMode)
  );

  return (
    <div className="module-wrapper">
      <Logo />
      <BreadcrumbStepper activeStep={3} />
      <h3 className="online-profile-header" data-testid="page-header">
        {t("GOLD_STANDARD")}
      </h3>
      <ShowValidSection isDataSubmitted={readOnlyRegistration}>
        <GoldStandard disabled={readOnlyRegistration} />
      </ShowValidSection>
      <Footer />
    </div>
  );
};

export default GoldStandardPage;
