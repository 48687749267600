import * as React from "react";
import styles from "./styles.module.scss";

interface MissingConfirmationContainerProps {
  children: React.ReactNode | React.ReactNode[] | string | string[];
}

export const MissingConfirmationContainer = ({
  children,
}: MissingConfirmationContainerProps) => (
  <section className={styles.missingConfirmationContainer}>{children}</section>
);
