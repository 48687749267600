import * as yup from "yup";
import { AnyObject, Flags, Maybe, Schema } from "yup";
import {
  filterForbiddenWordValidation,
  forbiddenWordValidation,
  patternValidation,
} from "./customValidations";
import {
  documentTypeValidation,
  expiryDateValidation,
  issueDateValidation,
} from "../personal-info/tins/validations/tinValidations";
import { TinRule } from "../../config/model/TinRules";
import {
  checkHasNoOnlySpecialSigns,
  dateOfBirthValidation,
  postalCodeValidation,
} from "../personal-info/personalDetails/validations/personalDetailsValidations";
import {
  attachmentExtensionValidation,
  attachmentSizeValidation,
} from "../personal-info/attachments/validations/attachmentValidations";

yup.addMethod(yup.string, "forbiddenWords", forbiddenWordValidation);
yup.addMethod(
  yup.string,
  "filterForbiddenWords",
  filterForbiddenWordValidation
);
yup.addMethod(yup.string, "matchesPattern", patternValidation);
yup.addMethod(yup.string, "hasCorrectIssueDate", issueDateValidation);
yup.addMethod(yup.string, "hasCorrectExpiryDate", expiryDateValidation);
yup.addMethod(yup.string, "hasCorrectDocumentType", documentTypeValidation);
yup.addMethod(yup.string, "hasCorrectDateOfBirth", dateOfBirthValidation);
yup.addMethod(yup.string, "matchesPostalCodeRegex", postalCodeValidation);
yup.addMethod(yup.string, "hasNoOnlySpecialSigns", checkHasNoOnlySpecialSigns);
yup.addMethod(
  yup.mixed,
  "matchesAttachmentExtensionRegex",
  attachmentExtensionValidation
);
yup.addMethod(yup.mixed, "hasCorrectSize", attachmentSizeValidation);

declare module "yup" {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext = AnyObject,
    TDefault = undefined,
    TFlags extends Flags = "",
  > extends yup.Schema<TType, TContext, TDefault, TFlags> {
    forbiddenWords(
      country: string,
      errorMessage: string
    ): StringSchema<TType, TContext>;
    filterForbiddenWords(
      forbiddenWords: string[],
      errorMessage: string
    ): StringSchema<TType, TContext>;
    matchesPattern(
      pattern: RegExp,
      errorMessage: string
    ): StringSchema<TType, TContext>;
    hasCorrectDomain(errorMessage: string): StringSchema<TType, TContext>;
    hasCorrectDateOfBirth(
      adultAge: number,
      errorMessage: string
    ): StringSchema<TType, TContext>;
    matchesPostalCodeRegex(country: string): StringSchema<TType, TContext>;
    hasCorrectIssueDate(): StringSchema<TType, TContext>;
    hasCorrectExpiryDate(): StringSchema<TType, TContext>;
    hasCorrectDocumentType(tinRule: TinRule): StringSchema<TType, TContext>;
    hasNoOnlySpecialSigns(errorMessage: string): StringSchema<TType, TContext>;
  }

  interface MixedSchema<TType, TContext, TDefault, TFlags>
    extends Schema<TType, TContext, TDefault, TFlags> {
    matchesAttachmentExtensionRegex(
      acceptedExtensions?: string[],
      errorMessage?: string
    ): MixedSchema<TType, TContext>;
    hasCorrectSize(): MixedSchema<TType, TContext>;
  }
}

export default yup;
