import { MutateOptions, useMutation } from "@tanstack/react-query";
import { useProfileStore } from "../profileStore";
import { ResponseError } from "../../common/api/ResponseError";
import axios from "axios";
import { SMS_PIN_VERIFICATION_PATH } from "../../../config/api";

const { verifyPin } = useProfileStore.getState();

export type VerifyPinRequest = {
  phoneNumberCountryCode: string;
  phoneNumberSubscriberNumber: string;
  verificationCode: string;
};

export const useVerifyPinMutation = () => {
  const verifyPinMutation = useMutation<
    unknown,
    ResponseError,
    VerifyPinRequest
  >({
    mutationFn: (request: VerifyPinRequest) => verifyPinCode(request),
    onSuccess: () => {
      verifyPin();
    },
  });

  return {
    ...verifyPinMutation,
    mutate: (
      ...params: [
        VerifyPinRequest,
        MutateOptions<unknown, ResponseError, VerifyPinRequest> | undefined,
      ]
    ) => {
      if (!verifyPinMutation.isPending) {
        verifyPinMutation.mutate(...params);
      }
    },
  };
};

const verifyPinCode = async (request: VerifyPinRequest) => {
  const response = await axios.post<void>(SMS_PIN_VERIFICATION_PATH, request);
  return response.data;
};
