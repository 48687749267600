import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { Navigate, Routes } from "react-router";
import { MUIProvider } from "../MUI";
import ScrollToTop from "../../utils/scrollToTop";
import { initTagManager } from "../../config/google";
import { initQuantumMetric } from "../../config/quantumMetrics";
import styles from "./App.module.scss";
import { ProtectedRoute } from "../ProtectedRoute/ProtectedRoute";
import { initializeSentry } from "../../config/sentry";
import LandingPage from "../../pages/Landing/LandingPage";
import { ExternalUrlRedirection } from "../ExternalUrlRedirection/ExternalRedirection";
import { Spinner } from "../common/Spinner";
import { lazyWithPreload } from "../../utils/lazyLoading";
import GoldStandardPage from "../../pages/GoldStandard/GoldStandardPage";
import MembershipAgreementPage from "../../pages/MembershipAgreement/MembershipAgreementPage";
import NotFoundPage from "../../pages/NotFoundPage/NotFoundPage";
import MaintenancePage from "../../pages/Maintenance/MaintenancePage";
import { useProfileStore } from "../../features/initialRegistration/profileStore";
import { initAdobeTag } from "../../config/adobeTag";
import { CookiePolicyPage } from "../../features/cookies/CookiePolicy/CookiePolicy";
import { useShallow } from "zustand/react/shallow";

export const PersonalInfoPage = lazyWithPreload(
  () => import("../../pages/PersonalInfo/PersonalInfoPage")
);

export const PaymentPage = lazyWithPreload(
  () => import("../../pages/Payment/PaymentPage")
);

export const PaymentStatusPage = lazyWithPreload(
  () => import("../../pages/PaymentStatus/PaymentStatus")
);

export const ConfirmationPage = lazyWithPreload(
  () => import("../../pages/Confirmation/ConfirmationPage")
);

export const NewConfirmationPage = lazyWithPreload(
  () => import("../../pages/NewConfirmation/NewConfirmationPage")
);

export const FiluetConfirmation = lazyWithPreload(
  () => import("../../pages/FiluetConfirmation/FiluetConfirmation")
);

export const OrderConfirmationPage = lazyWithPreload(
  () => import("../../pages/OrderConfirmation/OrderConfirmation")
);

export const App = () => {
  useEffect(() => {
    initAdobeTag();
  }, []);

  initTagManager();
  initializeSentry();
  initQuantumMetric();

  const country = useProfileStore(useShallow((state) => state.country));
  const isNewConfirmationPage =
    import.meta.env.VITE_NEW_CONFIRMATION_PAGE_COUNTRIES?.includes(country);

  return (
    <MUIProvider>
      <BrowserRouter
        future={{
          v7_startTransition: false,
          v7_relativeSplatPath: false,
        }}
      >
        <Suspense fallback={<Spinner />}>
          <div className={styles.App}>
            <ScrollToTop />
            <Routes>
              <Route
                path="/approved"
                element={<Navigate to="/filuet-confirmation" replace />}
              />
              <Route
                path="/pending"
                element={<Navigate to="/payment" replace />}
              />
              <Route
                path="/cancelled"
                element={<Navigate to="/payment" replace />}
              />
              <Route
                path="/declined"
                element={<Navigate to="/payment" replace />}
              />
              <Route
                path="/expired"
                element={<Navigate to="/payment" replace />}
              />
              <Route
                path="/"
                element={
                  <ExternalUrlRedirection
                    url={import.meta.env.VITE_SSO_COUNTRY_SELECTION_LINK}
                  />
                }
              />
              <Route path="/register" element={<LandingPage />} />
              <Route
                path="/personalinfo"
                element={
                  <ProtectedRoute>
                    <PersonalInfoPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/guarantees"
                element={
                  <ProtectedRoute>
                    <GoldStandardPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/agreement"
                element={
                  <ProtectedRoute>
                    <MembershipAgreementPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/payment"
                element={
                  <ProtectedRoute>
                    <PaymentPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/paymentstatus"
                element={
                  <ProtectedRoute>
                    <PaymentStatusPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/filuet-confirmation"
                element={
                  <ProtectedRoute>
                    <FiluetConfirmation />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/confirmation"
                element={
                  <ProtectedRoute>
                    {isNewConfirmationPage ? (
                      <NewConfirmationPage />
                    ) : (
                      <ConfirmationPage />
                    )}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/orderconfirmation"
                element={
                  <ProtectedRoute>
                    <OrderConfirmationPage />
                  </ProtectedRoute>
                }
              />
              <Route path="/maintenance" element={<MaintenancePage />} />
              <Route path="/cookiepolicy" element={<CookiePolicyPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
        </Suspense>
      </BrowserRouter>
    </MUIProvider>
  );
};
