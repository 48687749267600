import { useMutation } from "@tanstack/react-query";
import { useProfileStore } from "./profileStore";
import { ResponseError } from "../common/api/ResponseError";
import { logger } from "../../utils/logger";
import axios from "axios";
import { PHONE_NUMBER_VERIFICATION_PATH } from "../../config/api";

const { verifyNumber } = useProfileStore.getState();

type VerifyPhoneNumberRequest = {
  countryCode: string;
  phoneNumber: string;
};
export const useVerifyPhoneNumberMutation = () => {
  return useMutation<unknown, ResponseError, VerifyPhoneNumberRequest>({
    mutationFn: (request: VerifyPhoneNumberRequest) => {
      return verifyPhoneNumber(request.countryCode, request.phoneNumber);
    },
    onSuccess: () => {
      verifyNumber();
    },
    onError: (error) => {
      logger.error("Failed to verify phone number", error);
    },
  });
};

const verifyPhoneNumber = async (
  countryCode: string,
  subscriberNumber: string
) => {
  return axios.post(PHONE_NUMBER_VERIFICATION_PATH, {
    countryCode,
    subscriberNumber,
  });
};
