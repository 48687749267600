import { useHmpOwnershipStore } from "./hmpOwnershipStore";
import { WithTypeContaining } from "../../../types/utilityTypes";
import { MemberpackObtainingMethod } from "../../configuration/useConfigurationQuery";

export type HmpOwnershipValuesState = {
  memberpackObtainingMethod: MemberpackObtainingMethod | null;
  idNumber: string;
  onlineCode: string;
};

export type HmpOwnershipPayload = {
  memberpackObtainingMethod: MemberpackObtainingMethod;
  idNumber: string;
  onlineCode: string;
};

export const hmpOwnershipFromStore = (): HmpOwnershipValuesState => {
  return {
    memberpackObtainingMethod:
      useHmpOwnershipStore.getState().memberpackObtainingMethod,
    idNumber: useHmpOwnershipStore.getState().idNumber,
    onlineCode: useHmpOwnershipStore.getState().onlineCode,
  };
};

export const createHmpOwnershipFromForm = ({
  memberpackObtainingMethod,
  idNumber,
  onlineCode,
}: WithTypeContaining<HmpOwnershipValuesState>): HmpOwnershipPayload => {
  if (!memberpackObtainingMethod) {
    throw new Error("Memberpack obtaining method is required");
  }

  return {
    memberpackObtainingMethod,
    idNumber,
    onlineCode,
  };
};
