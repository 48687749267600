import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { WithTypeContaining } from "../../../types/utilityTypes";
import { useProfileStore } from "../../initialRegistration/profileStore";
import { getCountryConfig } from "../../../config/countries/countryUtil";

export type MarketingState = {
  beenMemberBefore: boolean | null;
  marketingConsentAccepted: boolean;
  dataPrivacyAcknowledgementAccepted?: boolean;
  documentationConfirmed: boolean;
  prospectOwnsSignificantSocialMediaAccount: boolean | null;
  socialMediaConsents: boolean[];
};

export type MarketingPayload = {
  beenMemberBefore: boolean;
  marketingConsentAccepted: boolean;
  dataPrivacyAcknowledgementAccepted?: boolean;
  documentationConfirmed: boolean;
  prospectOwnsSignificantSocialMediaAccount: boolean | null;
  socialMediaConsents?: boolean[];
};

interface MarketingAction {
  setMarketing: (
    personalInfoValues: WithTypeContaining<MarketingState>
  ) => void;
  reset: () => void;
}

const initialState: MarketingState = {
  beenMemberBefore: null,
  marketingConsentAccepted: false,
  dataPrivacyAcknowledgementAccepted: false,
  documentationConfirmed: false,
  prospectOwnsSignificantSocialMediaAccount: null,
  socialMediaConsents: [false, false, false, false, false, false],
};

export const useMarketingStore = create<MarketingState & MarketingAction>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        setMarketing: (personalInfoValues) => {
          set(createMarketingFromForm(personalInfoValues));
        },
        reset: () => set(initialState),
      }),
      {
        name: "marketing-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);

const isDataPrivacyAcknowledgementAccepted = (country: string) => {
  const isDataPrivacyAcknowledgement = Boolean(
    getCountryConfig(country)?.isDataPrivacyAcknowledgement
  );
  return isDataPrivacyAcknowledgement
    ? {
        dataPrivacyAcknowledgementAccepted:
          useMarketingStore.getState().dataPrivacyAcknowledgementAccepted,
      }
    : {};
};

const createMarketingFromForm = ({
  beenMemberBefore,
  marketingConsentAccepted,
  dataPrivacyAcknowledgementAccepted,
  documentationConfirmed,
  prospectOwnsSignificantSocialMediaAccount,
  socialMediaConsents,
}: WithTypeContaining<MarketingState>): MarketingState => ({
  beenMemberBefore,
  marketingConsentAccepted,
  dataPrivacyAcknowledgementAccepted,
  documentationConfirmed,
  prospectOwnsSignificantSocialMediaAccount,
  socialMediaConsents: socialMediaConsents || [],
});

export const marketingFromStore = () => {
  return {
    beenMemberBefore: useMarketingStore.getState().beenMemberBefore,
    marketingConsentAccepted:
      useMarketingStore.getState().marketingConsentAccepted,
    documentationConfirmed: useMarketingStore.getState().documentationConfirmed,
    prospectOwnsSignificantSocialMediaAccount:
      useMarketingStore.getState().prospectOwnsSignificantSocialMediaAccount,
    socialMediaConsents: useMarketingStore.getState().socialMediaConsents,
    ...isDataPrivacyAcknowledgementAccepted(useProfileStore.getState().country),
  };
};
