import * as React from "react";
import styles from "./styles.module.scss";

interface HeadlineProps {
  children: React.ReactNode | React.ReactNode[] | string | string[];
}

export const Headline = ({ children }: HeadlineProps) => (
  <h3 className={styles.headline} data-testid="headline">
    {children}
  </h3>
);
