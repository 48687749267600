import { MutateOptions, useMutation } from "@tanstack/react-query";
import { useSponsorshipStore } from "../sponsorshipStore";
import { ResponseError } from "../../../common/api/ResponseError";
import axios from "axios";
import { CHECK_SPONSOR_PATH } from "../../../../config/api";
import { getAbortController } from "../../../../utils/abortController";
import { SponsorshipResponse } from "../models/sponsorship";

export interface SponsorshipRequest {
  introducingMemberId: string;
  firstThreeLettersOfLastName: string;
}

const { confirmSponsorship } = useSponsorshipStore.getState();

export const useConfirmSponsorshipMutation = () => {
  const confirmSponsorshipMutation = useMutation<
    SponsorshipResponse,
    ResponseError,
    SponsorshipRequest
  >({
    mutationFn: (request: SponsorshipRequest) =>
      verifySponsor(
        request.introducingMemberId,
        request.firstThreeLettersOfLastName
      ),
    onSuccess: (response: SponsorshipResponse) => {
      confirmSponsorship(response);
    },
  });

  return {
    ...confirmSponsorshipMutation,
    mutate: (
      ...params: [
        SponsorshipRequest,
        MutateOptions<SponsorshipResponse, ResponseError, SponsorshipRequest>,
      ]
    ) => {
      if (!confirmSponsorshipMutation.isPending) {
        confirmSponsorshipMutation.mutate(...params);
      }
    },
  };
};

const verifySponsor = async (sponsorId: string, sponsorName: string) => {
  const response = await axios.post<SponsorshipResponse>(
    CHECK_SPONSOR_PATH,
    {
      introducingMemberId: sponsorId,
      firstThreeLettersOfLastName: sponsorName,
    },
    {
      signal: getAbortController().signal,
    }
  );
  return response.data;
};
