import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { PaymentStatus } from "../types/PaymentStatus";
import { PaymentProvider } from "../types/PaymentProvider";
import { CardProvider, PaymentMethod } from "../types/PaymentMethod";

type PaymentState = {
  status: PaymentStatus;
  provider: PaymentProvider;
  paymentUrl: string;
  paymentMethods: PaymentMethod[];
  selectedPaymentMethod: PaymentMethod | null;
  cardProviders: CardProvider[];
  isPIQPaymentSuccessful: boolean;
};

type PaymentAction = {
  setPaymentState: (
    paymentState: Omit<
      PaymentState,
      | "paymentMethods"
      | "cardProviders"
      | "selectedPaymentMethod"
      | "isPIQPaymentSuccessful"
    >
  ) => void;
  setPaymentStatus: (paymentStatus: PaymentStatus) => void;
  setPaymentMethodsAndCardProviders: (
    paymentMethods: PaymentMethod[],
    cardProviders: CardProvider[]
  ) => void;
  setSelectedPaymentMethod: (selectedPaymentMethod: PaymentMethod) => void;
  setPIQPaymentSuccessful: () => void;
  isPaymentInProgress: () => boolean;
  isPaymentSuccessful: () => boolean;
  isPaymentStarted: () => boolean;
  isPaymentCancelled: () => boolean;
  isPIQPayment: () => boolean;
  reset: () => void;
};

const isUnknownPaymentMethod = (paymentMethods: PaymentMethod[]) =>
  paymentMethods[0] === PaymentMethod.UNKNOWN;

export const usePaymentStore = create<PaymentState & PaymentAction>()(
  devtools(
    persist(
      (set, get) => ({
        status: PaymentStatus.NOT_STARTED,
        provider: PaymentProvider.NONE,
        paymentUrl: "",
        paymentMethods: [],
        cardProviders: [],
        isPIQPaymentSuccessful: false,
        selectedPaymentMethod: null,
        setPaymentStatus: (paymentStatus) =>
          set({
            status: paymentStatus,
          }),
        setPaymentState: (paymentState) =>
          set(
            (state) => ({
              status: paymentState.status ? paymentState.status : state.status,
              provider: paymentState.provider
                ? paymentState.provider
                : state.provider,
              paymentUrl: paymentState.paymentUrl
                ? paymentState.paymentUrl
                : state.paymentUrl,
            }),
            false,
            {
              type: "payment/setPaymentState",
              paymentState,
            }
          ),
        setPaymentMethodsAndCardProviders: (paymentMethods, cardProviders) => {
          set({
            paymentMethods,
            cardProviders,
          });

          if (isUnknownPaymentMethod(paymentMethods)) {
            get().setSelectedPaymentMethod(PaymentMethod.UNKNOWN);
          }
        },
        setSelectedPaymentMethod: (selectedPaymentMethod) =>
          set({ selectedPaymentMethod }),
        setPIQPaymentSuccessful: () => set({ isPIQPaymentSuccessful: true }),
        isPaymentInProgress: () => get().status === PaymentStatus.IN_PROGRESS,
        isPaymentSuccessful: () => get().status === PaymentStatus.SUCCESSFUL,
        isPaymentStarted: () => get().status !== PaymentStatus.NOT_STARTED,
        isPaymentCancelled: () => get().status === PaymentStatus.CANCELLED,
        isPIQPayment: () => get().provider === PaymentProvider.PIQ,
        reset: () => {
          set(() => ({
            status: PaymentStatus.NOT_STARTED,
            provider: PaymentProvider.NONE,
            paymentUrl: "",
            paymentMethods: [],
            cardProviders: [],
            selectedPaymentMethod: null,
            isPIQPaymentSuccessful: false,
          }));
        },
      }),
      {
        name: "payment-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);
