import { getForbiddenWordsForCountry } from "../../utils/forbiddenWordsForCountry";
import {
  CountryFormConfiguration,
  DEFAULT_COUNTRY_CODES,
  DEFAULT_NAMES_PATTERN,
} from "../constants";
import {
  DEFAULT_ADDRESS_LINE_PATTERN,
  defaultCountryConfigFieldLengths,
  defaultShippingAddressCareOfNameValidation,
} from "./defaults";

export const Malta: CountryFormConfiguration = {
  id: "en-MT",
  forms: {
    personalInfo: {
      firstName: {
        name: "firstName",
        label: "FIRST_NAME",
        type: "text",
        sanitize: true,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "FIRST_NAME_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.firstName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "FIRST_NAME_SIZE",
          },
          trim: true,
        },
      },
      lastName: {
        name: "lastName",
        label: "LAST_NAME",
        type: "text",
        sanitize: true,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "LAST_NAME_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.lastName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "LAST_NAME_SIZE",
          },
          trim: true,
        },
      },
      middleInitial: {
        name: "middleInitial",
        label: "INITIALS",
        type: "text",
        sanitize: true,
        validation: {
          required: { value: false },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "MIDDLE_INITIAL_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.middleInitial,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "LAST_NAME_SIZE",
          },
          trim: true,
        },
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.address,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: {
            value: getForbiddenWordsForCountry("en-MT"),
            errorMessage: "PO_BOX",
          },
          trim: true,
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.city,
            errorMessage: "TOO_LONG",
          },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 7, errorMessage: "TOO_LONG" },
          minLength: { value: 7, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: {
            value: /^[a-zA-Z]{2,3}[0-9]{4}$/,
            errorMessage: "POSTAL_CODE_SIZE",
          },
        },
      },
      dateOfBirth: {
        name: "dateOfBirth",
        label: "BIRTHDAY",
        type: "date",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          adultAge: { value: 18, errorMessage: "TOO_YOUNG" },
        },
      },
      tins: {
        name: "tins",
        label: "tins",
        type: "tins",
        validation: {
          required: { value: false },
        },
      },
    },
    shippingAddress: {
      careOfName: {
        name: "careOfName",
        label: "HMP_CARE_OF_NAME",
        type: "text",
        sanitize: true,
        validation: defaultShippingAddressCareOfNameValidation,
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.address,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: {
            value: getForbiddenWordsForCountry("en-MT"),
            errorMessage: "PO_BOX",
          },
          pattern: {
            value: DEFAULT_ADDRESS_LINE_PATTERN,
            errorMessage: "FORBIDDEN_WORD",
          },
          trim: true,
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.city,
            errorMessage: "TOO_LONG",
          },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 7, errorMessage: "TOO_LONG" },
          minLength: { value: 7, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: {
            value: /^[a-zA-Z]{2,3}[0-9]{4}$/,
            errorMessage: "POSTAL_CODE_SIZE",
          },
        },
      },
    },
  },
  addressLength: defaultCountryConfigFieldLengths.address,
  cityLength: defaultCountryConfigFieldLengths.city,
  postalCodeLength: 7,
  postalCodeMask: false,
  postalCodePattern: /^[a-zA-Z]{2,3}[0-9]{4}$/,
  postalCodeRequired: true,
  province: false,
  provinceLength: defaultCountryConfigFieldLengths.province,
  firstNameLength: defaultCountryConfigFieldLengths.firstName,
  genderFieldRequired: false,
  lastNameLength: defaultCountryConfigFieldLengths.lastName,
  lastNameFirst: false,
  middleInitial: true,
  middleInitialLength: defaultCountryConfigFieldLengths.middleInitial,
  middleInitialRequired: false,
  mothersMaidenName: false,
  mothersMaidenNameLength: defaultCountryConfigFieldLengths.mothersMaidenName,
  mothersMaidenNameRequired: false,
  showDateOfBirthDisclaimer: false,
  showDocumentationBox: false,
  showHmpBenefits: true,
  showHmpPickUpSection: false,
  showHmpPriceContent: true,
  showLatinInfo: false,
  showLinkToCookiePolicy: true,
  showLinkToOrderConfirmation: true,
  showPdfContainer: true,
  showResidentRadioBox: false,
  splitDeliveryTotal: false,
  spouseFirstName: false,
  spouseFirstNameLength: defaultCountryConfigFieldLengths.spouseFirstName,
  spouseMiddleName: false,
  spouseMiddleNameLength: defaultCountryConfigFieldLengths.spouseMiddleName,
  spouseLastName: false,
  spouseLastNameLength: defaultCountryConfigFieldLengths.spouseLastName,
  showOrderHandlerInformation: false,
  supportedCountryCodes: DEFAULT_COUNTRY_CODES,
} as const;
