const REQUEST_ABORTED = "CanceledError";
let abortController = new AbortController();

export const isRequestAborted = (err: unknown) => {
  return err instanceof Error && err.name === REQUEST_ABORTED;
};

export const getAbortController = () => abortController;

export const abortRequest = () => {
  try {
    abortController.abort();
  } catch (err) {
    if (!isRequestAborted(err)) {
      throw err;
    }
  } finally {
    abortController = new AbortController();
  }
};
