import * as yup from "yup";
import { StringSchema } from "yup";
import dayjs from "dayjs";
import { isSafari } from "../../../../utils/detect";
import { convertDateFormat } from "../../../../utils/convertDateFormat";

import { getCountryConfig } from "../../../../config/countries/countryUtil";
import { ONLY_SPECIAL_SIGNS_REGEX } from "../../../../config/constants";

export const postalCodeValidation = (country: string) => {
  return yup.string().test("postal-code-validation", function validate(value) {
    const { path, createError } = this;
    if (
      value &&
      !(getCountryConfig(country)?.postalCodePattern as RegExp)?.test(value)
    ) {
      return createError({ path, message: "POSTAL_CODE_SIZE" });
    }
    return true;
  });
};

export const dateOfBirthValidation = (
  adultAge: number,
  errorMessage: string
) => {
  return yup
    .string()
    .required("REQUIRED")
    .test("dateOfBirth-validation", function validate(value) {
      const { path, createError } = this;
      let date;
      if (isSafari) {
        date = convertDateFormat(value);
      } else {
        date = value;
      }
      const age = dayjs().diff(dayjs(date), "year");
      if (dayjs(date).toString() === "Invalid Date") {
        // TODO: verify error message
        return createError({ path, message: "TOO_OLD" });
      }
      if (age < adultAge) {
        return createError({ path, message: errorMessage });
      }
      if (age >= 120) {
        return createError({ path, message: "TOO_OLD" });
      }
      return true;
    });
};

export function checkHasNoOnlySpecialSigns(
  this: StringSchema,
  errorMessage: string
) {
  return this.test(
    "not-only-special-signs",
    errorMessage,
    (value) => !ONLY_SPECIAL_SIGNS_REGEX.test(value || "")
  );
}
