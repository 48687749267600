import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import {
  AttachmentsValues,
  createAttachmentsFromForm,
} from "./attachmentMapper";
import { WithTypeContaining } from "../../../types/utilityTypes";

interface FileMetadata {
  name?: string;
  type?: string;
}

export enum AttachmentType {
  RESIDENCE_PERMIT = "RESIDENCE_PERMIT",
  FACE_PHOTO = "FACE_PHOTO",
  TAX_NUMBER = "TAX_NUMBER",
  AUTHORIZATION_DOCUMENT = "AUTHORIZATION_DOCUMENT",
  OTHER = "OTHER",
}

export interface Attachment {
  fileMetadata: FileMetadata;
  awsKey: string;
  wasUploaded: boolean;
  name: string;
  type: AttachmentType;
  file?: File;
}

interface AttachmentsState {
  attachments: Attachment[];
}

interface AttachmentsAction {
  setAttachments: (
    personalInfoValues: WithTypeContaining<AttachmentsValues>
  ) => void;
  reset: () => void;
}

const initialState: AttachmentsState = {
  attachments: [],
};

export const useAttachmentsStore = create<
  AttachmentsState & AttachmentsAction
>()(
  devtools(
    persist(
      (set) => ({
        attachments: [],
        setAttachments: (personalInfoValues) => {
          set(createAttachmentsFromForm(personalInfoValues));
        },
        reset: () => set(initialState),
      }),
      {
        name: "attachments-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);

export const attachmentsFromStore = () => {
  return Object.fromEntries(
    useAttachmentsStore.getState().attachments.map((attachment) => [
      attachment.name,
      {
        file: attachment.fileMetadata
          ? new File([], attachment?.fileMetadata?.name || "", {
              type: attachment?.fileMetadata?.type,
            })
          : undefined,
        awsKey: attachment?.awsKey,
        wasUploaded: attachment?.wasUploaded,
        type: attachment?.type,
      },
    ])
  );
};
