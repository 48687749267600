import * as React from "react";
import styles from "./styles.module.scss";

interface ModuleContainerProps extends React.HTMLProps<HTMLElement> {
  children: React.ReactNode | React.ReactNode[] | string | string[];
}

export const ModuleContainer = ({
  children,
  ...props
}: ModuleContainerProps) => (
  <section {...props} className={styles.moduleContainer}>
    {children}
  </section>
);
