type ExternalUrlRedirectionProps = {
  url: string;
};

export const ExternalUrlRedirection = ({
  url,
}: ExternalUrlRedirectionProps) => {
  window.location.href = url;
  return null;
};
