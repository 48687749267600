import * as Sentry from "@sentry/browser";
import { anonymize } from "../utils/anonymize";

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT_NAME;

export const initializeSentry = () => {
  if (!SENTRY_DSN || import.meta.env.DEV) {
    return;
  }

  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration()],
    environment: ENVIRONMENT,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    beforeSend(event) {
      if (event.user) {
        event.user.email = event.user.email ? anonymize(event.user.email) : "";
      }
      return event;
    },
  });
};
