import TagManager from "react-gtm-module";

const TAG_MANAGER_ARGS = {
  gtmId: import.meta.env.VITE_GTM_ID,
};

export const initTagManager = () => {
  if (import.meta.env.PROD) {
    TagManager.initialize(TAG_MANAGER_ARGS);
  }
};
