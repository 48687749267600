import React from "react";
import { useTranslation } from "react-i18next";
import { UseMutationResult } from "@tanstack/react-query";
import { getOnlySpecificErrorKey, ResponseError } from "../api/ResponseError";
import styles from "../../../pages/Landing/LandingPage.module.scss";

type ServerErrorProps = {
  // For now is any from design, during time it seems that this component is not a great idea
  // we should remove it and show separate errors based on the mutation
  // https://mtsemea.atlassian.net/browse/OA-6218
  /* eslint-disable @typescript-eslint/no-explicit-any */
  mutations: UseMutationResult<unknown, ResponseError, any>[];
};
export const ServerErrorHandler = ({ mutations }: ServerErrorProps) => {
  const { t } = useTranslation();
  const isUnexpectedError = (error: ResponseError) =>
    !getOnlySpecificErrorKey(error);

  return (
    <>
      {mutations
        .filter(
          (mutation) => mutation.isError && isUnexpectedError(mutation.error)
        )
        .map((mutation) => {
          return (
            <span
              className={styles.serverError}
              role="alert"
              key={mutation?.error?.message}
            >
              {t("UNEXPECTED_EXCEPTION")}
            </span>
          );
        })}
    </>
  );
};
