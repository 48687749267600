import * as React from "react";
import styles from "./styles.module.scss";

interface MainContainerProps {
  children: React.ReactNode | React.ReactNode[] | string | string[];
}

export const MainContainer = ({ children }: MainContainerProps) => (
  <section className={styles.mainContainer}>{children}</section>
);
