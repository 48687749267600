import { useEffect, useState } from "react";
import i18n from "i18next";
import { useProfileStore } from "../../features/initialRegistration/profileStore";
import { useShallow } from "zustand/react/shallow";
import { IS_LOCAL } from "../../config/constants";

export const useTranslationState = () => {
  const country = useProfileStore(useShallow((state) => state.country));
  const [isTranslationLoading, setTranslationIsLoading] = useState<boolean>(
    !IS_LOCAL && i18n.resolvedLanguage !== country
  );

  useEffect(() => {
    if (i18n.resolvedLanguage === country) {
      setTranslationIsLoading(false);
    }

    const timer = setTimeout(() => {
      if (i18n.resolvedLanguage !== country && i18n.resolvedLanguage === "en") {
        setTranslationIsLoading(false);
      }
    }, 10000);

    return () => {
      setTranslationIsLoading(false);
      clearTimeout(timer);
    };
  }, [i18n.resolvedLanguage, country]);

  return { isTranslationLoading };
};
