import React, { forwardRef, Ref, SyntheticEvent, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { CountryType, getSupportedCountriesBy } from "./supported-countries";
import styles from "./styles.module.scss";
import { DefaultInputProps } from "../../../types/inputProps";

type CountryCodeProps = {
  disabled: boolean;
  dropdownWidth: number;
  value?: string;
  defaultValue?: string;
  error: boolean;
  onBlur: DefaultInputProps["onBlur"];
  onChange: (countryCodeWithName: string) => void;
  label: string;
};

export const CountryCode = forwardRef(
  (
    {
      dropdownWidth,
      value,
      defaultValue = "",
      error,
      onBlur,
      onChange,
      label,
      disabled = false,
    }: CountryCodeProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const { getValues } = useFormContext();
    const supportedCountries: readonly CountryType[] = getSupportedCountriesBy(
      getValues("country") as string
    );
    const [, countryName] = (value || "").split("|");
    const currentCountry: CountryType = useMemo(
      () =>
        supportedCountries?.filter(
          (country: CountryType) => country.name === countryName
        )[0],
      [value]
    );

    const onChangeCountryCode = (countryType: CountryType) =>
      onChange(`${countryType.phoneCode}|${countryType.name}`);

    const filterCountryByNameOrNumber = createFilterOptions({
      stringify: (option: CountryType) => option.name + option.phoneCode,
    });

    const isMobileView = () => dropdownWidth < 400;

    return (
      <div className={styles.countryCodeSelect} data-testid="country-code">
        <Autocomplete
          id="country-code-select"
          data-testid="country-code-select"
          sx={{ maxWidth: dropdownWidth }}
          componentsProps={{
            paper: {
              sx: {
                width: dropdownWidth * 0.8,
                maxWidth: dropdownWidth,
              },
            },
          }}
          defaultValue={defaultValue}
          value={currentCountry || null}
          options={supportedCountries}
          filterOptions={filterCountryByNameOrNumber}
          disableClearable
          autoHighlight
          getOptionLabel={(option) => (option as CountryType)?.phoneCode || ""}
          onBlur={onBlur}
          onChange={(
            _event: SyntheticEvent<Element, Event>,
            newValue: NonNullable<string | CountryType>
          ) => onChangeCountryCode(newValue as CountryType)}
          disabled={disabled}
          isOptionEqualToValue={(firstOption, secondOption) =>
            firstOption.isoCode === secondOption.isoCode
          }
          freeSolo
          clearOnBlur
          forcePopupIcon
          ListboxProps={{
            style: {
              maxHeight: "12rem",
            },
          }}
          renderOption={(props, option: CountryType) => (
            <Box
              component="li"
              sx={{
                "& > img": { mr: 2, flexShrink: 0 },
                color: "#515151 !important",
                fontWeight: "300",
                "&:hover": {
                  backgroundColor: "#266431 !important",
                  color: "#ffffff !important",
                  transition: "all 0.5s",
                },
              }}
              {...props}
              key={option.isoCode}
            >
              <div className={styles.countryOption}>
                <div className={styles.flag}>
                  <img
                    loading="lazy"
                    width="32"
                    srcSet={`https://flagcdn.com/w40/${option.isoCode.toLowerCase()}.png 2x`}
                    src={`https://flagcdn.com/w20/${option.isoCode.toLowerCase()}.png`}
                    alt=""
                  />
                  {option.name}
                </div>
                <div>{option.phoneCode}</div>
              </div>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              required
              {...params}
              inputRef={ref}
              label={label}
              error={error}
              InputProps={{
                ...params.InputProps,
                startAdornment:
                  currentCountry && !isMobileView() ? (
                    <InputAdornment position="start">
                      <img
                        loading="lazy"
                        width="32"
                        src={`https://flagcdn.com/w20/${currentCountry.isoCode?.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${currentCountry.isoCode?.toLowerCase()}.png 2x`}
                        alt=""
                      />
                    </InputAdornment>
                  ) : null,
                endAdornment: disabled ? null : (
                  <InputAdornment position="end">
                    {params.InputProps.endAdornment}
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </div>
    );
  }
);
