import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { useProfileStore } from "../../initialRegistration/profileStore";
import { getCountryConfig } from "../../../config/countries/countryUtil";

type DeliveryState = {
  herbalifeOrderNumber: string;
};

type DeliveryAction = {
  isShipping: () => boolean;
  setHerbalifeOrderNumber: (orderNumber: string) => void;
  reset: () => void;
};

export const useDeliveryStore = create<DeliveryState & DeliveryAction>()(
  devtools(
    persist(
      (set) => ({
        herbalifeOrderNumber: "",
        isShipping: () =>
          !getCountryConfig(useProfileStore.getState().country)
            ?.showHmpPickUpSection,
        setHerbalifeOrderNumber: (herbalifeOrderNumber: string) =>
          set({ herbalifeOrderNumber }),
        reset: () => set({ herbalifeOrderNumber: "" }),
      }),
      {
        name: "delivery-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);
